var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": true
    }
  }) : _vm._e(), _c('v-stepper', {
    attrs: {
      "elevation": 0
    },
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.CHOICE
    }
  }, [_vm.step === _vm.STEPS.CHOICE ? _c('v-row', {
    staticClass: "pa-2"
  }, _vm._l(_vm.choices, function (choice) {
    return _c('v-col', {
      key: choice.subtitle,
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-item', [_c('v-sheet', {
      staticClass: "pointer",
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": "info"
      },
      on: {
        "click": choice.onClick
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": ""
      }
    }, [_c('v-card-actions', {
      staticClass: "pt-8 pb-8"
    }, [_c('v-spacer'), _c('v-icon', {
      staticStyle: {
        "transform": "scale(2)"
      },
      attrs: {
        "x-large": "",
        "color": "info"
      }
    }, [_vm._v(" " + _vm._s(choice.icon) + " ")]), _c('v-spacer')], 1), _c('h3', {
      staticClass: "text-center mb-n2"
    }, [_vm._v(" " + _vm._s(choice.title) + " ")]), _c('v-card-subtitle', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(choice.subtitle) + " ")])], 1)], 1)], 1)], 1);
  }), 1) : _vm._e()], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.UPLOAD_NEW
    }
  }, [_vm.step === _vm.STEPS.UPLOAD_NEW ? _c('document-form', {
    ref: "documentForm",
    staticClass: "ml-4 mr-4 mb-2",
    attrs: {
      "folder": _vm.folder,
      "hideButton": true,
      "isLoading": _vm.isLoading,
      "hideTemplateOption": true
    },
    on: {
      "save": _vm.save,
      "updateValid": function updateValid(v) {
        return _vm.isValid = v;
      }
    }
  }) : _vm._e()], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.CHOOSE_EXISTING
    }
  }, [_vm.step === _vm.STEPS.CHOOSE_EXISTING ? _c('paginated-table', {
    attrs: {
      "store": _vm.documentStore,
      "predefinedFilter": _vm.documentStore.commonPredefinedFilter,
      "selectedItems": _vm.selectedItems,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "headers": _vm.headers,
      "showSelect": true,
      "singleSelect": false,
      "margin": false,
      "itemsPerPage": 8
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "click:row": function clickRow(d) {
        return _vm.selectedItems.push(d);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.isTemplate",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" " + _vm._s(item.isSigned ? "mdi-signature-freehand" : item.isTemplate ? "mdi-file-multiple-outline" : "mdi-file-document-outline") + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(item.isSigned ? _vm.$t("sign.DocumentDetail.signed") : item.isTemplate ? _vm.$t("sign.DocumentDetail.template") : _vm.$t("sign.DocumentDetail.file")) + " ")])])], 1)];
      }
    }], null, false, 151307346)
  }) : _vm._e()], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.STEPS.SUCCESS
    }
  }, [_vm.step === _vm.STEPS.SUCCESS ? _c('div', [_c('v-img', {
    staticClass: "mt-5 mb-5",
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-height": "300px",
      "src": require("@/assets/undraw/undraw_add_files_re_v09g.svg")
    }
  })], 1) : _vm._e()])], 1), !_vm.hideButton ? _c('v-card-actions', [_vm.onPrevious ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.isLoading,
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.template.dialog.preview.back")) + " ")]) : _vm._e(), _vm.onNext ? _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": _vm.rightDisabled,
      "loading": _vm.isLoading,
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.onNext
    }
  }, [_vm._v(" " + _vm._s(_vm.rightButtonText) + " ")]) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }